<template>
  <base-drawer-dialog
    v-model="drawer"
    stateless
  >
    <template v-slot:title>
      <div class="title-l-bold neutral-900--text">
        {{ isNew ? 'Новая бонусная валюта': 'Редактирование бонусной валюты' }}
      </div>
    </template>
    <v-row>
      <v-col>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col class="title-m-bold neutral-900--text">
              Общие параметры
            </v-col>
          </v-row>
          <dialog-form-block-row
            title="Название валюты"
            desc="Это название будет видно всем пользователям приложения Plus Cards."
          >
            <base-text-field
              v-model="bonusUnitInternal.name"
              :rules="nameRules"
              :validation-placement="'bottom'"
              class="input-lg input-bonuses"
              placeholder="Например, бонусы"
              error-style="vuetify"
              counter
              minlength="1"
              maxlength="45"
            />
          </dialog-form-block-row>

          <dialog-form-block-row
            title="Краткое описание валюты"
            desc="Это описание будет видно под названием"
          >
            <base-text-field
              v-model="bonusUnitInternal.description"
              :rules="descriptionRules"
              :validation-placement="'bottom'"
              class="input-lg input-bonuses"
              placeholder="Введите описание"
              error-style="vuetify"
              counter
              maxlength="100"
              validate-on-blur
            />
          </dialog-form-block-row>

          <dialog-form-block-row
            title="Склонения валюты"
            desc="Введите название валюты в разных склонениях. Багодаря этому достигается корректное отображение баланса."
          >
            <v-row no-gutters>
              <v-col cols="4">
                <base-text-field
                  v-model="bonusUnitInternal.unit_name_ending_first"
                  :rules="nameEndingRules"
                  :validation-placement="'bottom'"
                  class="name-ending-input"
                  placeholder="1 бонус"
                  maxlength="45"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="4">
                <base-text-field
                  v-model="bonusUnitInternal.unit_name_ending_second"
                  :rules="nameEndingRules"
                  :validation-placement="'bottom'"
                  class="name-ending-input"
                  placeholder="2 бонуса"
                  maxlength="45"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="4">
                <base-text-field
                  v-model="bonusUnitInternal.unit_name_ending_third"
                  :rules="nameEndingRules"
                  class="name-ending-input"
                  :validation-placement="'bottom'"
                  placeholder="5 бонусов"
                  maxlength="45"
                  validate-on-blur
                />
              </v-col>
            </v-row>
          </dialog-form-block-row>

          <dialog-form-block-row
            title="Передача валюты"
            desc="Даете ли вы возможность делиться и передавать друг другу валюту?"
          >
            <base-ext-switch
              v-model="bonusUnitInternal.can_transfer"
              label="Разрешить передавать валюту"
            />
          </dialog-form-block-row>

          <v-row>
            <v-col class="title-m-bold neutral-900--text">
              Опции валюты
            </v-col>
          </v-row>

          <dialog-form-block-row
            title="Выберите тип валюты"
            desc="Выберите один из предложенных типов валюты и настройте её параметры."
          >
            <v-radio-group
              v-model="bonusType"
              class="mt-0"
              hide-details

              @change="onChangeBonusType"
            >
              <v-radio
                v-for="item in bonusTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </v-radio-group>
          </dialog-form-block-row>

          <dialog-form-block-row
            v-if="['INTEGER'].includes(bonusType) "
            title="Соотношение бонусов к денежной валюте"
            desc="Настройте как ваша бонусная валюта относится к денежной валюте."
          >
            <v-select
              v-model="bonusUnitInternal.money_ratio"
              :items="moneyRatioList"
              :disabled="bonusUnitInternal.id != null"
              :rules="[
                v => !!v || 'Выберите соотношение'
              ]"
              class="mt-0"
              hide-details
            />
          </dialog-form-block-row>

          <select-icon-row
            v-if="['with_goal', 'without_goal'].includes(bonusType) "
            v-model="bonusUnitInternal.icon_set_id"
            :with-goal="bonusUnitInternal.with_goal"
          />

          <dialog-form-block-row
            v-if="['with_goal', 'without_goal'].includes(bonusType) "
            title="Ограничение баланса валюты"
            desc="Установите величину максимального значения баланса валюты. После достижения максимума баланса - произойдет срабатывание события 'Достижение максимума бонусов' и дальнейшие начисления по данной валюте прекратятся."
          >
            <v-row align="center">
              <v-col>
                <v-slider
                  v-model="bonusUnitInternal.max_value"
                  label=""
                  min="1"
                  max="100"
                  hide-details
                />
              </v-col>
              <v-col cols="auto">
                <base-text-field
                  v-model="bonusUnitInternal.max_value"
                  :rules="maxValueRules"
                  class="mt-0 pt-0"
                  single-line
                  type="number"
                  style="width: 60px"
                />
              </v-col>
            </v-row>
          </dialog-form-block-row>

          <dialog-form-block-row
            v-if="['INTEGER'].includes(bonusType) "

            title="Основная валюта"
            desc="Основная валюта отображается на карте в приложении, используется для построения всех графиков и диаграмм."
          >
            <template>
              <v-row>
                <v-col>
                  <base-ext-switch
                    v-model="bonusUnitInternal.is_main"
                    label="Использовать как основную"
                  />
                </v-col>
              </v-row>
              <v-row v-if="isMainUpdate">
                <v-col>
                  <span class="error--text body-m-regular">
                    У вас уже есть основная валюта <span class="body-m-semibold">«{{ mainBonusUnit.name }}»</span>. Если вы хотите использовать валюту <span class="body-m-semibold">«{{ bonusUnitInternal.name }}»</span> как основную, оставьте переключатель включенным.

                  </span>
                </v-col>
              </v-row>
            </template>
          </dialog-form-block-row>
          <dialog-form-block-row
            v-if="!bonusUnitInternal.is_main"
            title="Скрытие при нулевом балансе"
            desc="Настройте отображение валюты в приложении при нулевом балансе"
          >
            <template>
              <v-row>
                <v-col>
                  <base-ext-switch
                    v-model="bonusUnitInternal.hide_zero_balance"
                    label="Скрывать при нулевом балансе"
                  />
                </v-col>
              </v-row>
            </template>
          </dialog-form-block-row>
          <dialog-form-block-row
            title="Уведомления по бонусным операциям"
            desc="Настройте отправку PUSH-уведомления клиентам о бонусных операциях по данной валюте"
          >
            <template>
              <v-row>
                <v-col>
                  <base-ext-switch
                    v-model="bonusUnitInternal.notification_enabled"
                    label="Отправлять уведомления"
                  />
                </v-col>
              </v-row>
            </template>
          </dialog-form-block-row>
          <dialog-form-block-row
            title="Уведомления о сгорании бонусов"
            desc="Настройте отправку уведомлений клиентам о сгорании по данной валюте"
          >
            <template>
              <v-row>
                <v-col>
                  <base-ext-switch
                    v-model="bonusUnitInternal.expire_notification_enabled"
                    label="Отправлять уведомления"
                  />
                </v-col>
              </v-row>
              <template v-if="bonusUnitInternal.expire_notification_enabled">
                <v-row align="baseline">
                  <v-col cols="auto" class="body-m-regular neutral-700--text">Отправлять уведомления за</v-col>
                  <v-col cols="12">
                    <v-combobox
                        v-model="bonusUnitInternal.notification_settings_json.expire_delay_days_list"
                        placeholder="Выберите дни"
                        suffix="дней"
                        :items="numbersDays"
                        :rules="tagsRules"
                        :return-object="false"
                        hide-selected
                        hint=""
                        label=""
                        no-data-text=""
                        multiple
                        persistent-hint
                        chips
                        outlined
                        deletable-chips
                        clearable
                        @focusout="focusOutHandler"
                        @focusin="focusInHandler"
                        :menu-props="{ closeOnClick: fold }"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row  align="baseline">
                  <v-col cols="auto" class="body-m-regular neutral-700--text" style="min-width: 19em;">Уведомлять если сгораемая сумма >=</v-col>
                  <v-col>
                    <base-text-field
                    v-model.number="bonusUnitInternal.notification_settings_json.expire_min_value"
                    :rules="[
                      (v) => v>=0 || 'Больше или равно 0'
                    ]"                    
                    type="number"                                        
                    placeholder="X"                    
                    maxlength="12" 
                    validationPlacement="bot"                                      
                  />
                  </v-col>
                </v-row>
              </template>
              
            </template>
          </dialog-form-block-row>
          <dialog-form-block-row
              v-if="!bonusUnitInternal.is_main && !isNew"
              title="Ассортимент"
              desc="Выберите товары и/или категории товаров при покупке которых будет автоматически увеличиваться данная валюта."
          >
            <template>
              <v-row>
                <v-col cols="12">
                  <TreeselectNomenclature
                      :stepperData.sync="bonusUnitInternal"
                      @updateSelectTree="updateNomenclature"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="bonusUnitInternal.program_bonus_res_id"
                      label="Операция начисления"
                      :rules="selectProgramBonusResRules"
                      :items="filterBonusResources(this.bonusUnitInternal, 'TYPE_SOURCE')"
                      item-text="title"
                      item-value="id"
                      outlined
                      clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
            </template>
          </dialog-form-block-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row
      class="save-currency"
    >
      <v-col>
        <v-btn
          small
          :disabled="!allValid"
          color="primary"
          :loading="confirmAction"
          @click="confirmClick"
        >
          <v-icon left>
            $iconify_ion-checkmark-circle-outline
          </v-icon>

          <!-- <img
            src="@/icons/svg/checkmark-circle-outline.svg"
            class="img-circle"
          > -->
          {{ isNew ? 'Создать валюту' : 'Сохранить' }}
        </v-btn>
      </v-col>

      <v-spacer />
      <v-col>
        <v-btn
          v-if="!isNew"
          text
          color="error"
          :loading="deleteAction"
          @click="deleteClick"
        >
          <v-icon left>
            $iconify_feather-trash
          </v-icon>
          <span>Удалить валюту</span>
        </v-btn>
      </v-col>
    </v-row>
  </base-drawer-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
  import { isFilled, maxLen } from '@/utils/validate'
import {EVENTS_ENUM} from "@/models/enums";

  export default {
    name: 'BonusUnitDialog',
    components: {
      DialogFormBlockRow: () => import('./base/DialogFormBlockRow'),
      SelectIconRow: () => import('./SelectIconRow'),
      TreeselectNomenclature: () => import('./mainPage/components/TreeselectNomencalture'),
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      value: Boolean,
      bonusUnit: {
        type: Object,
        default: undefined,
      },
      programId: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        fold: false,
        selectProgramBonusRes: false,
        nameBonuses: '',
        bonusesFirst: '',
        bonusesSecond: '',
        bonusesThird: '',
        update: false,
        idUpdate: 0,
        allBonusesItems: [],
        // --
        valid: false,
        confirmAction: false,
        deleteAction: false,
        bonusUnitInternal: {
          name: '',
          type_enum: 'INTEGER',
          money_ratio: 1,
          max_value: null,
          with_goal: false,
          icon_set_id: null,
          unit_name_ending_first: '',
          unit_name_ending_second: '',
          unit_name_ending_third: '',
          can_transfer: false,
          cert_pay_available: false,
          is_main: false,
          hide_zero_balance: false,
          notification_enabled: true,
          expire_notification_enabled: false,
          notification_settings_json: {
            expire_delay_days_list: [],
            expire_min_value: 0
          }
        },
        nameRules: [
          (v) => isFilled(v) || 'Введите название валюты',
          (v) => maxLen(v, 45) || 'Не более 45 символов',
        ],
        nameEndingRules: [
          (v) => maxLen(v, 45) || 'Не более 45 символов',
        ],
        descriptionRules: [
          (v) => maxLen(v, 100) || 'Не более 100 символов',
        ],
        maxValueRules: [
          (v) => isFilled(v) || 'Заполните ограничение',
          (v) => v > 0 || 'Ограничение должно быть больше 0',
          (v) => v < 101 || 'Ограничение должно быть не больше 100'
        ],
        tagsRules: [
          (v) => !!v || 'Выберите ключевые слова',
          function(v){
            for (let i = 0; i < v.length; i++) {
              if(/[^0-9]/g.test(v[i])){
                return "Только цифры";
              }
              v[i] = parseInt(v[i]);
            }
            return true;
          },
          function (v) {
            for (let i = 0; i < v.length; i++) {
              if(v[i] > 31){
                return "Только числа до 31";
              }
            }
            return true;
          }
        ],
        bonusTypeList: [
          { value: 'INTEGER', label: 'Цифровая' },
          { value: 'with_goal', label: 'С целью' },
          { value: 'without_goal', label: 'Без цели' },
        ],
      }
    },
    computed: {

      ...mapGetters({
        bonusUnits: 'company/bonus_units/bonusUnits',
        mainBonusUnit: 'company/bonus_units/mainBonusUnit',
        bonusResources: 'company/bonus_resources/bonusResources',

        getOpenNavigationCreateBonuses: 'createBonusesCurrency/create_bonuses_currency/getOpenNavigationCreateBonuses',
        getBonusesItems: 'createBonusesCurrency/create_bonuses_currency/getBonusesItems',
        getUpdateBonusesItem: 'createBonusesCurrency/create_bonuses_currency/getUpdateBonusesItem',
        
      }),

      moneyRatioList() {
        return [
          { value: 100, text: this.numToStringCurrency(1) + ' =  100 бонусов' },
          { value: 10, text: this.numToStringCurrency(1) + ' = 10 бонусов' },
          { value: 1, text: this.numToStringCurrency(1) + ' = 1 бонус' },
          { value: -10, text: this.numToStringCurrency(10) + ' = 1 бонус' },
          { value: -100, text: this.numToStringCurrency(100) + ' = 1 бонус' },
        ]
      },

      numbersDays() {
        return [1, 2, 3, 5, 7, 14, 30, 45, 60, 90, 180]
      },

      selectProgramBonusResRules () {
        if (this.selectProgramBonusRes) {
          return [ v => !!v || 'Обязательно для заполнения']
        } else {
          return []
        }
      },

      program () {
        return this.$store.getters['company/program/program']
      },

      allValid () {
        let expiredBonusesValid = false
        if (this.bonusUnitInternal.expire_notification_enabled &&
            this.bonusUnitInternal.notification_settings_json.expire_delay_days_list.length > 0 )
          expiredBonusesValid = true
        if (!this.bonusUnitInternal.expire_notification_enabled)
          expiredBonusesValid = true
        return this.valid && expiredBonusesValid
      },
      isNew () {
        return !this.bonusUnit
      },
      // valid () {
      //   return this.bonusUnitInternal.name &&
      //     this.bonusUnitInternal.unit_name_ending_first &&
      //     this.bonusUnitInternal.unit_name_ending_second &&
      //     this.bonusUnitInternal.unit_name_ending_third
      // },
      drawer: {
        get () {
          return this.value
        },
        set (val) {
          if (val === this.value) return
          this.$emit('change', val)
        },
      },
      isMainUpdate () {
        return this.bonusUnitInternal.is_main && this.mainBonusUnit && this.mainBonusUnit.id !== this.bonusUnitInternal.id
      },

      bonusType: {
        get: function () {
          if (this.bonusUnitInternal.max_value != null) {
            return this.bonusUnitInternal.with_goal ? 'with_goal' : 'without_goal'
          } else {
            return 'INTEGER'
          }
        },
        set: function (v) {
          console.log('set bonusType', v, this.bonusUnitInternal)
          if (v === 'INTEGER' || v === 'FLOAT') {
            this.bonusUnitInternal.max_value = null
            this.bonusUnitInternal.type_enum = v
          } else {
            this.bonusUnitInternal.with_goal = v === 'with_goal'
            this.bonusUnitInternal.max_value = this.bonusUnitInternal.max_value || 1
            this.bonusUnitInternal.is_main = false
            this.bonusUnitInternal.type_enum = 'INTEGER'
          }
          console.log('after set bonusType', v, this.bonusUnitInternal)
        },
      },

    },
    watch: {
      getOpenNavigationCreateBonuses (val) {
        this.drawer = val
      },
      drawer (val) {
        if (!val) {
          this.clearNavigationRight()
        }
      },
      getUpdateBonusesItem (val) {
        if (Object.keys(val).length !== 0) {
          this.nameBonuses = val.nameBonuses
          this.bonusesFirst = val.bonusesFirst
          this.bonusesSecond = val.bonusesSecond
          this.bonusesThird = val.bonusesThird
          this.idUpdate = val.id
          this.update = true
        }
      },
      getBonusesItems (val) {
        this.allBonusesItems = val
      },
      "bonusUnitInternal.name" (val){
        console.log(val)
        this.$refs.form.validate()
      },
      "bonusUnitInternal.expire_notification_enabled"(val){
        console.log(val)
        this.$refs.form.validate()
      },
      "bonusUnitInternal.notification_settings_json.expire_delay_days_list"(val){
        console.log(val)
        this.$refs.form.validate()
      }
    },

    created () {
      if (this.bonusUnit) this.init(this.bonusUnit)
    },

    methods: {
      ...mapActions({
        getBonusResList: 'company/bonus_resources/GetList',
      }),
      ...mapMutations({
        openNavigationRight: 'createBonusesCurrency/create_bonuses_currency/openNavigationCreateBonuses',
        createBonusesItem: 'createBonusesCurrency/create_bonuses_currency/createBonusesItem',
        createAccrualBonuses: 'createBonusesCurrency/create_bonuses_currency/createAccrualBonuses',
        createMagazineBonuses: 'createBonusesCurrency/create_bonuses_currency/createMagazineBonuses',
        updateBonusesItem: 'createBonusesCurrency/create_bonuses_currency/updateBonusesItem',
        getWhatSelect: 'createBonusesCurrency/create_bonuses_currency/getWhatSelect',
        updateWhatSelect: 'createBonusesCurrency/create_bonuses_currency/updateWhatSelect',
      }),

      focusOutHandler () {
        this.fold = true
      },
      focusInHandler () {
        this.fold = false
      },

      filterBonusResources (bonusUnitRes, type) {
        return this.bonusResources.filter(
            item =>
                item.rules === null || item.rules.event === null &&
                item.resource_type_enum === type &&
                item.bonus_score &&
                item.bonus_score.units_id === bonusUnitRes.id
        )
      },

      updateNomenclature() {
        this.selectProgramBonusRes = this.bonusUnitInternal?.nomenclature_category_uuids?.length > 0
            || this.bonusUnitInternal?.nomenclature_uuids?.length > 0
      },

      numToStringCurrency(n) {
        if (this.program.currency && this.program.currency.alpha3) {
          return Number(n).toLocaleString(undefined, {style: this.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.program.currency.alpha3})
        } else {
          return n
        }
      },

      setCreateBonuses () {
        if (this.update) {
          const copyObj = Object.assign({}, this.createUpdateObjBonuses())
          for (let i = 0; i < this.allBonusesItems.length; i++) {
            if (this.allBonusesItems[i].id === this.idUpdate) {
              this.allBonusesItems[i] = copyObj
              this.updateWhatSelect(copyObj)
            }
            this.drawer = false
          }
        } else {
          if (this.getBonusesItems.length === 0) {
            this.createMagazineBonuses(this.createObjMagazineBonuses())
            this.createAccrualBonuses(this.createObjAccrualBonuses())
            this.createBonusesItem(this.createObjNameBonuses())
            this.drawer = false
          } else {
            this.createBonusesItem(this.createObjNameBonuses())
            this.drawer = false
          }
        }
      },
      createObjNameBonuses () {
        return {
          nameBonuses: this.nameBonuses,
          bonusesFirst: this.bonusesFirst,
          bonusesSecond: this.bonusesSecond,
          bonusesThird: this.bonusesThird,
          id: this.getBonusesItems.length + 1,
        }
      },
      createObjAccrualBonuses () {
        return {
          setting: false,
          selectName: this.nameBonuses,
          id: this.getBonusesItems.length + 1,
          countBy: '',
          checkTimeLimit: true,
          day: 365,
          confusedNameSelect: '',
        }
      },
      createObjMagazineBonuses () {
        return {
          setting: false,
          selectName: this.nameBonuses,
          id: this.getBonusesItems.length + 1,
          countBy: '',
        }
      },
      createUpdateObjBonuses () {
        return {
          nameBonuses: this.nameBonuses,
          bonusesFirst: this.bonusesFirst,
          bonusesSecond: this.bonusesSecond,
          bonusesThird: this.bonusesThird,
          id: this.idUpdate,
        }
      },
      clearNavigationRight () {
        this.openNavigationRight(false)
        this.nameBonuses = ''
        this.bonusesFirst = ''
        this.bonusesSecond = ''
        this.bonusesThird = ''
        if (Object.keys(this.getUpdateBonusesItem).length !== 0) {
          this.update = false
          this.updateBonusesItem({})
        }
      },
      // ---
      onChangeBonusType (v) {
        console.log('onChangeBonusType', v)
      },

      async deleteClick () {
        try {
          this.deleteAction = true
          await this.$store.dispatch('company/bonus_units/deleteBonusUnit', this.bonusUnit.id)
          this.close()
        } catch (error) {
          console.error(error)
        } finally {
          this.deleteAction = false
        }
      },

      async confirmClick () {

        try {
          this.confirmAction = true

          const postData = {
            program_id: this.programId,
            max_value: this.bonusUnitInternal.max_value,
            icon_set_id: this.bonusUnitInternal.icon_set_id,
            with_goal: this.bonusUnitInternal.with_goal,
            name: this.bonusUnitInternal.name,
            description: this.bonusUnitInternal.description,
            type_enum: this.bonusUnitInternal.type_enum,
            money_ratio: this.bonusUnitInternal.money_ratio,
            unit_name_ending: {
              first: this.bonusUnitInternal.unit_name_ending_first,
              second: this.bonusUnitInternal.unit_name_ending_second,
              third: this.bonusUnitInternal.unit_name_ending_third,
            },
            cert_pay_available: this.bonusUnitInternal.cert_pay_available,
            can_transfer: this.bonusUnitInternal.can_transfer,
            is_main: this.bonusUnitInternal.is_main,
            hide_zero_balance: this.bonusUnitInternal.hide_zero_balance,
            notification_enabled: this.bonusUnitInternal.notification_enabled,
            expire_notification_enabled: this.bonusUnitInternal.expire_notification_enabled,
            notification_settings_json: this.bonusUnitInternal.notification_settings_json &&
                                        this.bonusUnitInternal.notification_settings_json.expire_delay_days_list &&
                                        this.bonusUnitInternal.notification_settings_json.expire_delay_days_list.length > 0 ? 
                this.bonusUnitInternal.notification_settings_json : [],
            nomenclature_category_uuids: this.bonusUnitInternal.nomenclature_category_uuids,
            nomenclature_uuids: this.bonusUnitInternal.nomenclature_uuids,
            filter_nomenclature_segment_id: this.bonusUnitInternal.filter_nomenclature_segment_id,
            all_nomenclature: this.bonusUnitInternal.all_nomenclature,
            null_category_selected: this.bonusUnitInternal.null_category_selected,
            program_bonus_res_id: this.bonusUnitInternal.program_bonus_res_id
          }

          if (this.isNew) {
            await this.$store.dispatch('company/bonus_units/createBonusUnit', postData)
          } else {
            postData.id = this.bonusUnit.id
            await this.$store.dispatch('company/bonus_units/updateBonusUnit', postData)
          }
          this.close()
        } catch (error) {
          console.error(error)
        } finally {
          this.confirmAction = false
        }
      },
      close () {
        this.drawer = false
      },
      async init (bonusUnit) {
        console.log('init', bonusUnit)
        this.bonusUnitInternal = Object.assign(this.bonusUnitInternal, bonusUnit)
        await this.getBonusResList(this.programId)
        if (bonusUnit.unit_name_ending) {
          this.bonusUnitInternal.unit_name_ending_first = bonusUnit.unit_name_ending.first
          this.bonusUnitInternal.unit_name_ending_second = bonusUnit.unit_name_ending.second
          this.bonusUnitInternal.unit_name_ending_third = bonusUnit.unit_name_ending.third
        }        
        // init notification_settings_json
        if (!this.bonusUnitInternal.notification_settings_json) {
          this.bonusUnitInternal.notification_settings_json = {
            expire_delay_days_list: [],
            expire_min_value: 0
          }
        }        
      },
    },

  }
</script>
<style scoped>
  .name-ending-input {
    margin: 0px 6px;
  }
  .navigation-drawers-wrap {
    padding: 34px;
  }
  .navigation-title {
    margin: 50px 0 34px;
  }
  .title-currency {
    margin-bottom: 20px;
  }
  .name-currency {
    margin-bottom: 34px;
  }
  .input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px -6px;
  }
  .declines-currency {
    margin-bottom: 34px;
  }
  .allow-transfer-currency {
    display: flex;
    align-items: center;
  }
  .transfer-currency {
    margin-bottom: 48px;
  }
</style>
