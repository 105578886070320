var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openBonusUnitDialog()}}},[_c('v-icon',{staticStyle:{"width":"15px","height":"15px","margin-right":"8px","font-size":"16px"}},[_vm._v(" fa-plus ")]),_vm._v(" Добавить валюту ")],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"plus-table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.tableData,"options":_vm.tableSettings,"single-expand":true,"item-key":"uuid","show-expand":false,"hide-default-footer":"","item-class":function (item) { return item.is_main? ['main-bonus-unit', 'body-m-semibold']: ''; }},on:{"update:options":function($event){_vm.tableSettings=$event},"click:row":function($event){return _vm.openBonusUnitDialog($event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[_c('v-row',[_c('v-col',[(item.is_main)?_c('v-icon',[_vm._v("mdi-star")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)],1)],1)]}},{key:"item.is_main",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(_vm.getCurrencyType(item))+" ")])]}},{key:"item.max_value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.max_value ? item.max_value : "-")+" ")])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"cell-img",attrs:{"size":"42"}},[(item.icon_set_id)?_c('img',{staticStyle:{"padding":"2px"},attrs:{"src":_vm.getIcon(item.icon_set_id) ? _vm.getIcon(item.icon_set_id) : item.icon,"alt":"иконка валюты"}}):_vm._e()])]}}])})],1)],1),_c('v-row',{staticClass:"pagination",attrs:{"align":"center"}},[_c('v-col',[_c('div',{staticClass:"table-pagination-block"},[_c('div',{staticStyle:{"margin-right":"20px"}},[_vm._v(" Всего "+_vm._s(_vm.totalCount)+" "+_vm._s(_vm.getWord(_vm.totalCount, _vm.wordOperations))+" на "+_vm._s(_vm.pagesCount)+" "+_vm._s(_vm.getWord(_vm.pagesCount, _vm.wordPages))+" ")]),_c('select-page-limit',{attrs:{"min-width":"200px","items":_vm.paginationOptions,"model":_vm.tableSettings.itemsPerPage,"item-value":"value","item-label":"text"},on:{"update:model":function($event){return _vm.$set(_vm.tableSettings, "itemsPerPage", $event)}}}),_c('div',{staticClass:"app__spacer"}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"next-icon":"fas fa-chevron-right","prev-icon":"fas fa-chevron-left","length":_vm.pagesCount,"total-visible":7,"circle":""},model:{value:(_vm.tableSettings.page),callback:function ($$v) {_vm.$set(_vm.tableSettings, "page", $$v)},expression:"tableSettings.page"}})],1)],1)])],1),(_vm.bonusDialog)?_c('bonus-unit-dialog',{attrs:{"bonus-unit":_vm.editedBonusUnit,"program-id":_vm.programId},model:{value:(_vm.bonusDialog),callback:function ($$v) {_vm.bonusDialog=$$v},expression:"bonusDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }