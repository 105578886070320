<template>
  <div>
    Advanced
  </div>
</template>

<script>
  export default {
    name: 'Advanced',
    methods: {
      async save () {
        console.log(this.name + 'todo save')
      },
    },
  }
</script>

<style scoped>

</style>
